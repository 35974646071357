"use strict";

import gallerySlider from "./modules/gallerySlider.js";
import hamburger from "./modules/hamburger.js";
import reviewsSlider from "./modules/reviewsSlider.js";
import headerBg from "./modules/headerBg.js";


try {
    gallerySlider();
} catch (error) {
    
}

try {
    reviewsSlider();
} catch (error) {
    
}


hamburger();
headerBg();


