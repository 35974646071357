const reviewsSlider = () => {

  $(document).ready(function(){
    $('.reviews__wrapper').slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: $('.reviews__prev'),
      nextArrow: $('.reviews__next'),
      centerMode: true,
      centerPadding: '0',
      responsive: [
        {
          breakpoint: 1051,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '0',
            slidesToShow: 3,
            variableWidth: true
          }
        }]

    });
  }); 

};

export default reviewsSlider;

