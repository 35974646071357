const gallerySlider = () => {

  $(document).ready(function(){
    $('.gallery__slider').slick({

      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '0',
      prevArrow: $('.prev'),
      nextArrow: $('.next'),
      focusOnSelect: true,
      initialSlide: 1
    });
  }); 

};

export default gallerySlider;

